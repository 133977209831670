<script type="text/jsx">
  export default {
    name: 'RouteView',
    data () {
      return {}
    },
    render () {
      const { $route: { meta }, $store: { getters } } = this

      const inKeep = (
      <keep-alive>
        <router-view />
      </keep-alive>
    )
      const notKeep = (
      <router-view />
    )
      return meta.keepAlive || getters.multiTab ? inKeep : notKeep
    }
  }
</script>
